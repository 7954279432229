import { IMetaAction } from "../../index";
export interface IDeleteStudyState {
  readonly data: IDeleteStudyRaw;
  readonly loading?: boolean;
  readonly pathName?: string;
}

export type ApiResponse = Record<string, any>;
export interface IDeleteStudyRaw extends ApiResponse {
  results: DeleteData[];
}

export type DeleteData = {
  message: string;
};

export type IDeletePayload = {
  STUDY_CODE: string | null;
};

export const DeleteActionTypes = {
  DELETE_SEARCH_STUDY: "@@Delete/FETCH_SEARCH_STUDY",
  DELETE_SEARCH_STUDY_SUCCESS: "@@Delete/FETCH_SEARCH_STUDY_SUCCESS",
  DELETE_SEARCH_STUDY_ERROR: "@@Delete/FETCH_SEARCH_STUDY_ERROR",
};

export interface IDispatchToProps {
  deleteStudy: () => IMetaAction;
}
