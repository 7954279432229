import { take, put, race } from "redux-saga/effects";
import {
  confirmNo,
  confirmYes,
  hideConfirmation,
  showConfirmation,
} from "./ModalActions";

export function* confirmSaga(confirmationMessage: string, open: boolean) {
  yield put(showConfirmation());
  const { yes } = yield race({
    yes: take(confirmYes),
    no: take(confirmNo),
  });

  yield put(hideConfirmation());
  return yes.payload;
}
