import React from "react";
import { UserProfile } from "../../anothercomponents/body";
import { Header } from "../../anothercomponents/header";
import { ErrorInfoType } from "../../components/Hoc/WithErrorBoundary";
import im from "./image.png";

const AddStudyPages: React.FC = () => {
  return (
    <div>
      <Header />
      <UserProfile />
    </div>
  );
};

export default AddStudyPages;
