import React, { useEffect } from "react";
import { useJwtInfo } from "../../hooks/JwtInfo";
import {
  AppBar,
  Content,
  TopToolbar,
  Title,
  User,
  StyleBadge,
  Avatar,
  BottomToolbar,
  StudyName,
  Logout,
} from "./styles";
import {
  IDispatchToProps,
  IEnvironmentRaw,
  IEnvironmentState,
} from "../../State/Environment/EnvironementTypes";
import { CircularProgress } from "@material-ui/core";
import { HeaderContent } from "./HeaderContent";

type AllProps = IEnvironmentState & IDispatchToProps;

const Header: React.FC<AllProps> = ({
  data,
  fetchEnvironment,
  pathName,
  loading,
}: AllProps) => {
  const { initials } = useJwtInfo();
  const result = data as IEnvironmentRaw[0];
  useEffect(() => {
    fetchEnvironment();
  }, [fetchEnvironment]);
  return (
    <AppBar position="static" elevation={1}>
      <Content>
        <TopToolbar>
          <HeaderContent></HeaderContent>
          {/* <User>
            <StyleBadge
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar>{initials}</Avatar>
            </StyleBadge>
          </User> */}
        </TopToolbar>
        {/* {loading ? (
          <CircularProgress disableShrink />
        ) : (
          <BottomToolbar>
            <StudyName>
              {result.service} | Study:{"not found"}
            </StudyName>

            <Logout>Logout</Logout>
          </BottomToolbar>
        )} */}
      </Content>
    </AppBar>
  );
};

export default Header;
