import { IModalState, ModalActionTypes } from "./ModalTypes";
export const initialState: IModalState = {
  isModalOpen: false,
  errors: [],
  loading: false,
  pathName: "",
};
export const modalReducer = (
  state: IModalState = initialState,
  action: { type: string; payload: boolean },
): IModalState => {
  switch (action.type) {
    case ModalActionTypes.OPEN_MODAL: {
      return { ...state, isModalOpen: true };
    }
    case ModalActionTypes.CLOSE_MODAL: {
      return { ...state, isModalOpen: false };
    }
    default:
      return state;
  }
};
