import React from "react";
import theme from "./config/theme";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import AppRoot from "./components/AppRoot";
import { Provider } from "react-redux";
import { History } from "history";
import { Store } from "redux";
import { IApplicationState } from "./State";
import { ConnectedRouter } from "connected-react-router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStroopwafel } from "@fortawesome/free-solid-svg-icons";

library.add(faStroopwafel);

interface MainProps {
  store: Store<IApplicationState>;
  history: History;
}

const App: React.FC<MainProps> = ({ store, history }) => {
  return (
    // <ErrorBoundary>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <CssBaseline />
          <AppRoot />
        </ConnectedRouter>
      </ThemeProvider>
    </Provider>
    // </ErrorBoundary>
  );
};

export default App;
