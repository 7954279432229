import React from "react";
import { IconButton, styled, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";

type props = {
  index: string;
};
const DeleteIconComponent: React.FC<props> = ({ index }) => {
  const history = useHistory();
  return (
    <ButtonWrapper>
      <Tooltip title="Delete">
        <IconButton
          aria-label="delete"
          style={{ padding: 3 }}
          onClick={(event) => {
            event.stopPropagation();
            return history.push({
              pathname: `/study-search/${index}`,
            });
          }}
        >
          <DeleteIcon style={{ fontSize: 18 }} />
        </IconButton>
      </Tooltip>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled("span")({
  position: "absolute",
  top: 14,
  bottom: 0,
  left: 0,
  right: 0,
  marginLeft: "55%",
});

export default DeleteIconComponent;
