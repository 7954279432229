import { IMetaAction } from "..";
export interface IEnvironmentState {
  readonly data: IEnvironmentRaw | {};
  readonly loading?: boolean;
  readonly errors: [];
  readonly pathName?: string;
}

export type ApiResponse = Record<string, any>;
export interface IEnvironmentRaw extends ApiResponse {
  status: number;
  username: string;
  service: string;
}
export const EnvironmentActionTypes = {
  FETCH_ENVIRONMENT: "@@Study/FETCH_ENVIRONMENT",
  FETCH_ENVIRONMENT_SUCCESS: "@@Study/FETCH_ENVIRONMENT_SUCCESS",
  FETCH_ENVIRONMENT_ERROR: "@@Study/FETCH_ENVIRONMENT_ERROR",
};

export interface IDispatchToProps {
  fetchEnvironment: () => IMetaAction;
}
