import { Paper } from "@material-ui/core";
import React from "react";
import { useLocation } from "react-router-dom";

export const StudyInformationPage: React.FC = () => {
  const location = useLocation();
  const message = (location.state as IState).message;

  return (
    <div>
      <h1> Study information</h1>
      <div>Research Project Code: {message.RES_PROJ_ID}</div>
      <div>Study Code: {message.STUDY_CODE}</div>
      <div>Facility Code:{message.FACILITY_CODE}</div>
      <div>Study Status:{message.STUDY_STATUS}</div>
      <div>Alternative Study Name:{message.ALTERNATIVE_STUDY_NAME}</div>
      <div>Enrollment Status:{message.ENROLLMENT_STATUS}</div>
      <div>Dispensing Status:{message.DISPENSING_STATUS}</div>
    </div>
  );
};

interface IState {
  message?: any;
}
