import { action } from "typesafe-actions";
import { DeleteActionTypes } from "./DeleteStudyTypes";
import { ISearchStudyRaw } from "../SearchStudyTypes";
export const deleteStudy = (id: string) =>
  action(DeleteActionTypes.DELETE_SEARCH_STUDY, [], {
    method: "post",
    route: `/study/delete/${id}`,
  });
export const deleteStudySuccess = (data: ISearchStudyRaw) =>
  action(DeleteActionTypes.DELETE_SEARCH_STUDY_SUCCESS, data);
export const deleteStudyError = (message: string) =>
  action(DeleteActionTypes.DELETE_SEARCH_STUDY_ERROR, message);
