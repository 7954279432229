import { orderBy, find } from "lodash";

export interface Rank {
  keyName: string;
  rankOrder: string[];
}
export type Ranks = Rank[];

export const sortListBy = <T>(
  array: T[],
  sortedBy: keyof T,
  ascDec: (boolean | "asc" | "desc")[],
  ranks?: Ranks,
) => {
  const arrayWithSortRanks = mapSortRanks<T>(array, sortedBy, ranks);
  const mappedKeyFields = createMappedKeyFields<T>(ranks);
  const sortKeys = mappedKeyFields[sortedBy] || sortedBy;
  const sorted = orderBy<T>(arrayWithSortRanks, sortKeys, ascDec);

  return sorted.map((listItem) =>
    Object.fromEntries(
      Object.entries(listItem).filter(([key]) => key in array[0]),
    ),
  ) as T[];
};

const createMappedKeyFields = <T>(ranks?: Ranks) =>
  !!ranks
    ? ranks.reduce(
        (iterator, { keyName }) => ({
          ...iterator,
          ...{ [keyName]: `${keyName}Ranking` },
        }),
        {} as T,
      )
    : ({} as T);

const mapSortRanks = <T>(array: T[], sortedBy: keyof T, ranks?: Ranks) => {
  const activeRank = find<Ranks>(ranks, { keyName: sortedBy });

  return array.map((listItem: any) => ({
    ...listItem,
    [`${sortedBy}Ranking`]: (activeRank as Rank)?.rankOrder.indexOf(
      listItem[sortedBy],
    ),
  }));
};
