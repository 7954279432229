import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../State";
import { fetchEnvironment } from "../../../State/Environment/EnvironmentActions";
import { IEnvironmentState } from "../../../State/Environment/EnvironementTypes";
import Header from "../Header";

const HeaderContainer = () => {
  const dispatch = useDispatch();

  const stateToProps: IEnvironmentState = useSelector(
    ({ env, router }: IApplicationState) => {
      return {
        loading: env.loading,
        errors: env.errors,
        data: env.data,
        pathName: router?.location.pathname,
      };
    },
  );

  const dispatchToProps = {
    fetchEnvironment: useCallback(
      () => dispatch(fetchEnvironment({ env: "dvz722" })),
      [dispatch],
    ),
  };

  return <Header {...stateToProps} {...dispatchToProps} />;
};
export default HeaderContainer;
