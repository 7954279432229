import React from "react";
// import styled from "styled-components";
// import { themeColor } from 'styleguide/colors';
import { styled } from "@material-ui/core";

export const StyledLocation = styled("p")({
  fontWeight: "bold",
  color: "#e60052",
});
// const StyledLocation = styled.p`
//   font-weight:bold;
//   color: #e60052;
// `

export const Location = ({ location }) => (
  <StyledLocation>{location}</StyledLocation>
);
