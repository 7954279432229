const composeQueryString = (query: {
  [s: string]: string | number | undefined | null;
}) => {
  const queryString = Object.entries(query)
    .map(([key, val]) => `${key}=${val && val.toString()}`)
    .join("&");
  return `?${queryString}`;
};

export default composeQueryString;
