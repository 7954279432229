import React from "react";
import "./DeleteStyle.css";
import { Typography } from "@material-ui/core";
import {
  ActionButtoContainer,
  CancelButton,
  Container,
  DeleteButton,
} from "./Style";
import CheckBox from "./CheckBox";
import { IDispatchToProps } from "../../../State/SearchStudy/Delete/DeleteStudyTypes";
import { ISearchStudyState } from "../../../State/SearchStudy/SearchStudyTypes";
import ConfirmDialogContainer from "../../Modal/containers/ConfirmContainer";
import { history } from "../../../index";
import { Detail } from "./Detail";
import { StyledTypography } from "../../Modal/Style";
type AllProps = ISearchStudyState & IDispatchToProps;
const DeleteStudy: React.FC<AllProps> = ({
  data,
  loading,
  pathName,
  deleteStudy,
}: AllProps) => {
  const onDeleteIconClick = () => {
    deleteStudy();
  };
  return (
    <div>
      <Typography variant={"h6"} color={"primary"}>
        Maintain Study - study code - {pathName?.split("/")[2]}
      </Typography>
      <hr />
      <Container>
        <Typography variant={"h5"} color={"primary"}>
          Warning! You are about to delete this study.
        </Typography>
        <StyledTypography>
          Please indicate if the accounts, person data, organization data, and
          questionnaire data associated with this study and only to this study
          will be deleted.
        </StyledTypography>
        <CheckBox />
        <Detail />
        <ActionButtoContainer>
          <DeleteButton color="default" onClick={() => onDeleteIconClick()}>
            Delete
          </DeleteButton>
          <CancelButton color="default" onClick={() => history.goBack()}>
            Cancel
          </CancelButton>
        </ActionButtoContainer>
      </Container>
      <ConfirmDialogContainer />
    </div>
  );
};

export default DeleteStudy;
