type EnvVars = Partial<{
  NODE_ENV: string;
  REACT_APP_API_URL: string;
}>;

export default class Environment {
  constructor(public config = process.env as EnvVars) {
    this.config = config;
  }

  get nodeEnv() {
    return this.config.NODE_ENV;
  }

  get apiUrl() {
    return this.config.REACT_APP_API_URL;
  }
}
