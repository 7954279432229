import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import { confirmSaga } from "../../Modal/ConfirmSaga";
import { IApplicationState, IMetaAction } from "../../index";
import { DeleteActionTypes } from "./DeleteStudyTypes";
import { deleteStudyError, deleteStudySuccess } from "./DeleteActions";
import { ISearchStudyRaw, StudyData } from "../SearchStudyTypes";
/**
 * @desc Business logic of effect.
 */
function* handleFetch(action: IMetaAction): Generator {
  try {
    const confirmed = yield call(confirmSaga, "open dialog", true);
    console.log(confirmed);
    if (!confirmed) {
      return;
    }
    const state = yield select();
    const result = state as IApplicationState;
    const studies: ISearchStudyRaw[] | any = result.post;
    console.log(studies.data);
    const updated = studies.data.results.filter((item: StudyData) => {
      return item.RES_PROJ_ID !== action.payload;
    });

    yield put(
      deleteStudySuccess({
        allStudiesCount: updated.length,
        resultsCount: updated.length,
        results: studies.data.results.slice(1),
      }),
    );
  } catch (err) {
    if (err instanceof Error) {
      yield put(deleteStudyError(err.stack!));
    } else {
      yield put(deleteStudyError("An unknown error occured."));
    }
  }
}

/**
 * @desc Watches every specified action and runs effect method and passes action args to it
 */
function* watchFetchRequest(): Generator {
  yield takeLatest(DeleteActionTypes.DELETE_SEARCH_STUDY, handleFetch);
}

/**
 * @desc saga init, forks in effects, other sagas
 */
export default function* deleteStudySaga() {
  yield all([fork(watchFetchRequest)]);
}
