import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { ColumnType } from "../../pages/StudySearchPage/Container/SearchResult";
import DeleteIconComponent from "./DeleteIconComponent";
import { useHistory } from "react-router-dom";

export const Row: React.FC<{
  rowValue: any;
  columns: ColumnType[];
}> = ({ rowValue, columns }) => {
  const [showAction, setShowAction] = React.useState(false);

  const history = useHistory();

  return (
    <TableRow
      onMouseEnter={() => setShowAction(true)}
      onMouseLeave={() => setShowAction(false)}
      hover
      onClick={(event) => {
        event.stopPropagation();
        return history.push({
          pathname: "/study-setup/study-information",
          state: { message: rowValue },
        });
      }}
    >
      {columns.map((column, index) => (
        <TableCell
          key={column.field}
          style={{
            ...(showAction ? { fontWeight: "bold" } : { fontWeight: "normal" }),
          }}
        >
          {index === 0 ? (
            <>
              {rowValue[column.field]}
              {showAction && (
                <DeleteIconComponent index={rowValue[column.field]} />
              )}
            </>
          ) : (
            rowValue[column.field]
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default Row;
