import { useState, useEffect } from "react";
import { Ranks, sortListBy } from "./sort-list-by";

export const useListOrder = <T>(
  initialArray: T[],
  sortDefault: keyof T,
  ranks?: Ranks,
) => {
  const [sortedList, setSortedList] = useState<T[]>(initialArray);
  const [isDesc, setIsDesc] = useState<boolean>(true);
  const [orderBy, setOrderBy] = useState<keyof T>(sortDefault);

  const selectList = (orderSelect: keyof T) => {
    setOrderBy(orderSelect);

    const isSelected = orderBy === orderSelect;
    setIsDesc((isDesc) => (isSelected ? !isDesc : true));
  };

  useEffect(() => {
    const direction = isDesc ? "desc" : "asc";
    setSortedList((array) => sortListBy<T>(array, orderBy, [direction], ranks));
  }, [orderBy, isDesc, ranks]);

  return {
    selectList,
    sortedList,
    orderBy,
    direction: (isDesc ? "desc" : "asc") as "desc" | "asc",
  };
};
