import axios from "axios";
import Environment from "../config/environment";
import Cookies from "js-cookie";
const accessToken = Cookies.get("accessToken");
export default function HttpService<T>(
  method: string,
  path: string,
  data?: any,
): Promise<T[] | null> {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios
    .post(new Environment().apiUrl + path, data[0], options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.message;
    });
}
