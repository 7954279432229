import { Action, PayloadAction, TypeConstant } from "typesafe-actions";
import {
  ISearchStudyRaw,
  ISearchStudyState,
  SearchStudyActionTypes,
} from "./SearchStudyTypes";
import { DeleteActionTypes } from "./Delete/DeleteStudyTypes";
export const initialState: ISearchStudyState = {
  data: { results: [], resultsCount: 0, allStudiesCount: 0 },
  error: "",
  loading: false,
  pathName: "",
  deleted: {},
};
export const searchStudyReducer = (
  state: ISearchStudyState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, ISearchStudyRaw>,
): ISearchStudyState => {
  switch (action.type) {
    case SearchStudyActionTypes.FETCH_SEARCH_STUDY: {
      return { ...state, loading: true };
    }
    case SearchStudyActionTypes.FETCH_SEARCH_STUDY_SUCCESS: {
      console.log(action.payload);
      return { ...initialState, data: action.payload };
    }
    case DeleteActionTypes.DELETE_SEARCH_STUDY_SUCCESS: {
      console.log(action.payload);
      return {
        ...initialState,
        data: action.payload,
      };
    }
    case SearchStudyActionTypes.FETCH_SEARCH_STUDY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};
