import React from "react";

type props = {
  title?: string;
};
export const MyProfilePage: React.FC<props> = ({ title }: props) => {
  return (
    <h1 style={{ height: "200px", background: "green" }}>My Profile Page</h1>
  );
};
