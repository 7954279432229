import React from "react";
import NewComponent from "./components/NewComponent";
import AddStudyPages from "../AddStudyPages/AddStudyPages";

export interface HomePageProps {}

const HomePage: React.FC<HomePageProps> = () => {
  return (
    <div>
      <AddStudyPages />
    </div>
  );
};

export default HomePage;
