import React from "react";
import { IRoute } from "../Navigation/Routes";
import { Route } from "react-router-dom";
import { Error } from "../../pages/Error/Error";
import { WithErrorBoundary } from "../Hoc/WithErrorBoundary";

const AppRoutes: React.FC<{ routes: IRoute[] }> = ({ routes }) => (
  <>
    <Route path="/error" component={Error} />
    {routes.map((route: IRoute) => {
      if (!route.nested) {
        return (
          <Route
            exact
            path={route.path}
            key={route.path}
            component={WithErrorBoundary(route.component)}
          />
        );
      }
      return route.nested.map((nestedRoute: IRoute) => (
        <Route
          exact
          path={nestedRoute.path}
          key={nestedRoute.path}
          component={WithErrorBoundary(nestedRoute.component)}
        />
      ));
    })}
  </>
);

export default AppRoutes;
