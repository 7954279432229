import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { Chip, TextField } from "@material-ui/core";

export const AutocompleteInput: React.FC<{
  label: string;
  options: any;
  value: any;
  setValue: (value: any) => void;
}> = ({ label, options, value, setValue }) => (
  <Autocomplete
    id="size-small-filled"
    fullWidth
    options={options}
    getOptionLabel={(options) => options.title || ""}
    getOptionSelected={(options, value) => options.title === value.title}
    value={value}
    onChange={(event, newValue) => {
      setValue(newValue);
    }}
    style={{ width: "100%" }}
    renderTags={(value, getTagProps) =>
      value.map((option, index) => (
        <Chip
          variant="outlined"
          label={option.title}
          {...getTagProps({ index })}
        />
      ))
    }
    renderInput={(params) => (
      <TextField {...params} variant="filled" label={label} />
    )}
  />
);
