import React from "react";
// import styled from 'styled-components';
// import { gray } from 'styleguide/colors';
// import { mobileScreenSize } from 'styleguide/breakpoints';
import { styled } from "@material-ui/core";

export const StyledBiography = styled("p")({
  color: "grey",
  margin: "0 auto",
});

// const StyledBiography = styled.p`
//   color: ${gray};
//   @media screen and (min-width:500px){
//     max-width: 900px;
//   }
//   margin: 0 auto;
// `

export const Biography = ({ biography }) => (
  <StyledBiography>{biography}</StyledBiography>
);
