import React from "react";
// import styled from 'styled-components';
// import { grayLight, themeColor } from 'styleguide/colors';

// const StyledLink = styled.a`
//   color: ${grayLight};
//   font-weight: bold;
//   text-decoration: none;
//   cursor: pointer;
//   &:hover{
//     color: ${themeColor}
//   }
// `

// const StyledSpan = styled.span`
//   margin-top: auto;
//   display: block;
//   align-items: center;
// `
import { styled } from "@material-ui/core";

export const StyledLink = styled("a")({
  color: "grey",
  fontWeight: "bold",
  textDecoration: "none",
  cursor: "pointer",
});

export const StyledSpan = styled("span")({
  marginTop: "auto",
  display: "block",
  alignItems: "center",
});

export const DesignCredits = ({ content, link, arialLabel }) => (
  <StyledSpan>
    Design by:
    <StyledLink
      rel="noreferrer"
      role="link"
      href={link}
      arial-label={arialLabel}
    >
      {" "}
      {content}{" "}
    </StyledLink>
  </StyledSpan>
);
