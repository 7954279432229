import React, { useCallback } from "react";
import DeleteStudy from "../DeleteStudy";
import { useDispatch, useSelector } from "react-redux";
import { deleteStudy } from "../../../../State/SearchStudy/Delete/DeleteActions";
import { IApplicationState } from "../../../../State";

const DeleteStudyContainer = () => {
  const dispatch = useDispatch();

  const stateToProps = useSelector(({ post, router }: IApplicationState) => {
    return {
      data: post.data,
      error: post.error,
      pathName: router?.location.pathname,
    };
  });

  const dispatchToProps = {
    deleteStudy: useCallback(() => dispatch(deleteStudy("1")), [dispatch]),
  };
  return <DeleteStudy {...stateToProps} {...dispatchToProps} />;
};

export default DeleteStudyContainer;
