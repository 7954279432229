import { styled, Button, CircularProgress } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

export const SearchButton = styled(Button)({
  backgroundColor: "#000000",
  width: 140,
  height: 40,
  color: "white",
  marginTop: 10,
  "&:hover": {
    backgroundColor: "#000000",
  },
});

export const ProgressButton = styled(CircularProgress)({
  color: green[500],
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: -12,
  marginLeft: -12,
});

export const ResetButton = styled(Button)({
  color: "black",
  backgroundColor: "#FFFFFF",
  width: 140,
  height: 40,
  marginLeft: 38,
  marginTop: 10,
});

export const Row = styled("div")({
  display: "flex",
  marginTop: 25,
});

export const RowNext = styled("div")({
  display: "flex",
  marginTop: 30,
});

export const Column = styled("div")({
  width: 249,
  marginLeft: 25,
});

export const FirstColumn = styled("div")({
  width: 249,
  marginLeft: 0,
});
