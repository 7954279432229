import React from "react";
import { IDispatchToProps, IModalState } from "../../State/Modal/ModalTypes";
import { useDispatch } from "react-redux";
import { confirmNo, confirmYes } from "../../State/Modal/ModalActions";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { NoButton, YesButton } from "./Style";

type AllProps = IModalState & IDispatchToProps;

const ConfirmationDialog: React.FC<AllProps> = ({ isModalOpen }: AllProps) => {
  const dispatch = useDispatch();

  const onDeleteClick = () => {
    dispatch(confirmYes(true));
  };
  const onCancelClick = () => {
    dispatch(confirmNo(true));
  };
  return (
    <>
      <Dialog open={isModalOpen} aria-labelledby="confirm-dialog">
        <DialogTitle id="confirm-dialog">Delete Study</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this study
        </DialogContent>
        <DialogActions>
          <NoButton
            variant="contained"
            onClick={() => onCancelClick()}
            color="secondary"
          >
            No
          </NoButton>
          <YesButton
            variant="contained"
            onClick={() => onDeleteClick()}
            color="default"
          >
            Yes
          </YesButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
