import React from "react";
import { TableContainer, Table as MUITable, styled } from "@material-ui/core";
import { TableBody, Headers } from ".";
import { useListOrder } from "./services/use-list-order";
import { ReusablePagination } from "./ReusablePagination";
import { ColumnType } from "../../pages/StudySearchPage/Container/SearchResult";

export interface TableProps<T> {
  data: T[];
  sortDefault: keyof T;
  ranks?: { keyName: string; rankOrder: string[] }[];
  columns: ColumnType[];
}

const TableComponent = <T extends object>({
  data,
  sortDefault,
  ranks,
  columns,
}: TableProps<T>) => {
  const { selectList, sortedList, orderBy, direction } = useListOrder<T>(
    data,
    sortDefault,
    ranks,
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);

  return (
    <Container>
      <ReusablePagination
        rows={data}
        page={page}
        rowsPerPage={rowsPerPage}
        count={data.length}
        setRowsPerPage={setRowsPerPage}
        setPage={setPage}
      ></ReusablePagination>
      <TableContainer>
        <MUITable>
          <Headers<T>
            headers={columns}
            onHeaderSelect={selectList}
            direction={direction}
            activeHeader={orderBy}
          />
          <TableBody
            data={sortedList.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage,
            )}
            columns={columns}
          />
          {!sortedList.length && !!data.length && "...no data"}
        </MUITable>
      </TableContainer>
    </Container>
  );
};

const Container = styled("div")(({ theme }) => ({
  overflow: "auto",
}));

export default TableComponent;
