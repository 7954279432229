import React from "react";
type props = {
  error: Error;
  resetErrorBoundary: () => void;
};

const ErrorCallBack: React.FC<props> = ({
  error,
  resetErrorBoundary,
}: props) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

export default ErrorCallBack;
