import Cookies from "js-cookie";
import Environment from "../config/environment";
const apiCaller = async <T>(
  method: string,
  path: string = "",
  data?: any,
): Promise<T[] | null> => {
  const { apiUrl } = new Environment();
  const token = Cookies.get("accessToken");

  const options = {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: data ? JSON.stringify(data) : null,
  };

  const res = await fetch(apiUrl + path, options);

  const json = await res.json();

  if (res.status !== 200) {
    console.error(JSON.stringify(json));
    throw new Error(JSON.stringify(json));
  }

  return json;
};

export default apiCaller;
