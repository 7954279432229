import React from "react";
import {
  TableHead,
  TableSortLabel,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { ColumnType } from "../../pages/StudySearchPage/Container/SearchResult";

export interface HeadersProps<T> {
  headers: ColumnType[];
  onHeaderSelect: (orderSelect: keyof T) => void;
  activeHeader: keyof T;
  direction?: "desc" | "asc";
}

export const Headers = <T extends object>({
  headers,
  onHeaderSelect,
  activeHeader,
  direction,
}: HeadersProps<T>) => (
  <TableHead>
    <TableRow>
      {headers.map((header, index) => (
        <TableCell
          key={index}
          onClick={() => onHeaderSelect(header.field as keyof T)}
        >
          <TableSortLabel
            active={header.field === activeHeader}
            direction={header.field === activeHeader ? direction : "desc"}
            hideSortIcon={false}
          >
            {header.title}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
