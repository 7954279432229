import { combineReducers } from "redux";
import { all, fork } from "redux-saga/effects";
import {
  Action,
  PayloadAction,
  PayloadMetaAction,
  TypeConstant,
} from "typesafe-actions";
import { History } from "history";
import { connectRouter, RouterState } from "connected-react-router";
import { environmentReducer } from "./Environment/EnvironemntReducers";
import { IEnvironmentState } from "./Environment/EnvironementTypes";
import environmentSaga from "./Environment/EnvironmentSagas";
import { ISearchStudyState } from "./SearchStudy/SearchStudyTypes";
import { searchStudyReducer } from "./SearchStudy/SearchStudyReducers";
import searchStudySaga from "./SearchStudy/SearchStudySagas";
import { IModalState } from "./Modal/ModalTypes";
import { modalReducer } from "./Modal/ModalReducers";
import modalSaga from "./Modal/ModalSagas";
import deleteStudySaga from "./SearchStudy/Delete/DeleteStudySagas";
// The top-level state object
export interface IApplicationState {
  env: IEnvironmentState;
  post: ISearchStudyState;
  modal: IModalState;
  router: RouterState;
}
export interface IMetaAction
  extends PayloadMetaAction<TypeConstant, IPayLoad, IMeta> {}
export interface IReducerAction<TPayload>
  extends Action<TypeConstant>,
    PayloadAction<TypeConstant, TPayload> {}

export const rootReducer = (history: History) =>
  combineReducers<IApplicationState>({
    env: environmentReducer,
    post: searchStudyReducer,
    modal: modalReducer,
    router: connectRouter(history),
  });
export function* rootSaga() {
  yield all([fork(environmentSaga)]);
  yield all([fork(searchStudySaga)]);
  yield all([fork(deleteStudySaga)]);
  yield all([fork(modalSaga)]);
}
interface IMeta {
  method: string;
  route: string;
}
interface IPayLoad {}
