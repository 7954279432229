import { styled, Typography, Table } from "@material-ui/core";

export const TitleStyle = styled(Typography)({
  flex: "100%",
  color: "#808080",
  fontSize: "22px",
});

export const PaginationStyle = styled("div")({
  flexShrink: 0,
});

export const TableDiv = styled("div")({
  width: "100%",
});

export const TableStyl = styled(Table)({
  minWidth: 750,
});
export const RightAlignHeader = styled("span")({
  alignItems: "center",
  marginLeft: "auto !important",
  display: "flex",
});
