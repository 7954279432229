import React, { useState } from "react";
import "./DeleteStyle.css";
import { FormControlLabel } from "@material-ui/core";
import { CheckBoxContainer } from "./Style";
import Checkbox from "@material-ui/core/Checkbox";
const CheckBox: React.FC = () => {
  const [state, setState] = useState({
    checkedA: true,
    checkedB: true,
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <CheckBoxContainer>
      <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedA}
            onChange={handleChange}
            name="checkedA"
            color="default"
          />
        }
        label="Delete Person Data"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedB}
            onChange={handleChange}
            name="checkedB"
            color="default"
          />
        }
        label="Delete Organization Data"
      />
    </CheckBoxContainer>
  );
};

export default CheckBox;
