import {
  Button,
  createStyles,
  styled,
  Theme,
  withStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: "1rem",
}));

export const CheckBoxContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
});

export const ActionButtoContainer = styled("div")({
  display: "flex",
  flexDirection: "row-reverse",
  width: "100%",
  padding: "2rem 0 2rem 0",
});

export const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#6f6f6f",
      color: theme.palette.common.white,
      fontWeight: 800,
    },
    body: {
      fontSize: 12,
    },
  }),
)(TableCell);

export const DeleteButton = styled(Button)({
  backgroundColor: "#000000",
  width: 140,
  height: 40,
  color: "white",
  marginTop: 10,
  "&:hover": {
    backgroundColor: "#000000",
  },
});

export const CancelButton = styled(Button)({
  color: "black",
  backgroundColor: "#b6b6b6",
  width: 140,
  height: 40,
  marginRight: 38,
  marginTop: 10,
});

export const StyledTableContainer = styled("div")({
  marginTop: 20,
});
