import { action } from "typesafe-actions";
import { ModalActionTypes } from "./ModalTypes";
export const showConfirmation = () => {
  console.log("Action    000");
  return action(
    ModalActionTypes.OPEN_MODAL,
    { open: true },
    {
      method: "open",
      route: "/modal",
    },
  );
};
export const hideConfirmation = () => {
  return action(
    ModalActionTypes.CLOSE_MODAL,
    { open: false },
    {
      method: "close",
    },
  );
};
export const confirmYes = (data: boolean) =>
  action(ModalActionTypes.MODAL_OK_CLICK, true);
export const confirmNo = (data: boolean) =>
  action(ModalActionTypes.MODAL_CANCEL_CLICK, false);
