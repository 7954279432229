import React, { useEffect } from "react";
import { MenuItem, Select, TablePagination } from "@material-ui/core";
import { RightAlignHeader } from "./styles";
import TablePaginationActions from "./TablePaginationActions";
export interface ReusablePaginationProps<T> {
  rows: T[];
  page: number;
  rowsPerPage: number;
  count: number;
  setRowsPerPage: (rpp: number) => void;
  setPage: (cp: number) => void;

  onChangePage?: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

export const ReusablePagination = <T extends object>({
  rows,
  page,
  rowsPerPage,
  setRowsPerPage,
  setPage,
}: ReusablePaginationProps<T>) => {
  // const [page, setPage] = React.useState("");
  const [items, setItems] = React.useState([1]);

  const allowedState = (rows: T[], rowsPerPage: number) => {
    const length = Math.ceil(rows.length / rowsPerPage);
    const numberOfPages = Array.from({ length }, (_, i) => i + 1);
    return numberOfPages.sort((n1, n2) => n1 - n2);
  };

  useEffect(() => {
    setItems(allowedState(rows, rowsPerPage));
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  const menuItems = items.map((item, index) => (
    <MenuItem key={index} value={item}>
      {item}
    </MenuItem>
  ));

  const handleGoToPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    const pageNumber = parseInt(event.target.value as string, 10);
    setPage(pageNumber - 1);
    handleChangePage(event, pageNumber - 1);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <span style={{ display: "flex" }}>
      <div>
        <h3 style={{ color: "grey" }}>Search Results:</h3>
      </div>
      <RightAlignHeader>
        <span>
          <span>
            Go to page:{"  "}
            <Select
              disableUnderline
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              value={page + 1}
              onChange={handleGoToPage}
            >
              {menuItems}
            </Select>
          </span>
        </span>
        <span>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, 200, { label: "All", value: -1 }]}
            colSpan={3}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </span>
      </RightAlignHeader>
    </span>
  );
};
