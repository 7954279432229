import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../State";
import ConfirmationDialog from "../ConfirmationDialog";

import { IModalState } from "../../../State/Modal/ModalTypes";
import { showConfirmation } from "../../../State/Modal/ModalActions";

const ConfirmDialogContainer = () => {
  const dispatch = useDispatch();

  const stateToProps: IModalState = useSelector(
    ({ modal, router }: IApplicationState) => {
      return {
        loading: modal.loading,
        errors: modal.errors,
        isModalOpen: modal.isModalOpen,
        pathName: router?.location.pathname,
      };
    },
  );

  const dispatchToProps = {
    showConfirmation: useCallback(() => dispatch(showConfirmation()), [
      dispatch,
    ]),
  };
  return <ConfirmationDialog {...stateToProps} {...dispatchToProps} />;
};
export default ConfirmDialogContainer;
