import { all, fork } from "redux-saga/effects";
import { ModalActionTypes } from "./ModalTypes";
import { take } from "redux-saga-test-plan/matchers";

/**
 * @desc Watches every specified action and runs effect method and passes action args to it
 */
function* watchOpenRequest(): Generator {
  yield take(ModalActionTypes.MODAL_OK_CLICK);
  yield take(ModalActionTypes.MODAL_CANCEL_CLICK);
}

/**
 * @desc saga init, forks in effects, other sagas
 */
export default function* modalSaga() {
  yield all([fork(watchOpenRequest)]);
}
