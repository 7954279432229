import { action } from "typesafe-actions";
import { IEnvironmentRaw, EnvironmentActionTypes } from "./EnvironementTypes";
export const fetchEnvironment = (data: Object) =>
  action(EnvironmentActionTypes.FETCH_ENVIRONMENT, [data], {
    method: "post",
    route: "/study/env",
  });
export const fetchEnvironmentSuccess = (data: IEnvironmentRaw) =>
  action(EnvironmentActionTypes.FETCH_ENVIRONMENT_SUCCESS, data);
export const fetchEnvironmentError = (message: string) =>
  action(EnvironmentActionTypes.FETCH_ENVIRONMENT_ERROR, message);
