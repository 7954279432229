import React from "react";
import Routes from "../Navigation/Routes";

import { styled } from "@material-ui/core";
import AppRoutes from "./AppRoutes";
import { Grid } from "@material-ui/core";
import Navigation from "../Navigation/Navigation";
import HeaderContainer from "../Header/containers/HeaderContainer";

const AppRoot: React.FC = () => {
  return (
    <>
      <HeaderContainer />
      <Grid container>
        <AppContainer item xs={12} sm={12} md={9}>
          <AppRoutes routes={Routes} />
        </AppContainer>
      </Grid>
    </>
  );
};

const AppContainer = styled(Grid)(({ theme }) => ({
  padding: "4rem 2rem",
  [theme.breakpoints.down("sm")]: {
    padding: "0",
  },
}));

export default AppRoot;
