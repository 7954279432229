import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  mixins: {},

  overrides: {
    MuiTextField: {
      root: {
        "& label.Mui-focused": {
          color: "#6200EE",
        },
        "& label": {
          color: "#808080",
        },
        "& .MuiFilledInput-underline:after": {
          borderBottomColor: "#6200EE",
        },
        "& .MuiFilledInput-input:focus": {
          borderBottomColor: "#6200EE",
        },
      },
    },

    MuiTableRow: {
      root: {
        "&:nth-child(even)": {
          "& td": {
            backgroundColor: "#ECECEC",
            zIndex: -1,
          },
        },
        "&&.MuiTableRow-hover": {
          "&:hover": {
            cursor: "pointer",
            boxShadow: "0 10px 10px 0px grey, inset 0 0 0 1px #000000",
            position: "relative",
            "& td": {
              backgroundColor: "unset",
              zIndex: 1,
            },
          },
        },
      },
    },

    MuiSelect: {
      root: {
        border: "1px solid #D3D3D3",
        borderRadius: "4px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 0 4px 0 rgba(0,0,0,0.5)",
        borderBottom: "0",
        paddingLeft: "20px",
        "& .MuiInput-underline:before": {
          borderBottom: "0",
        },
        "& .MuiInput-underline:after": {
          borderBottom: "0",
        },
      },
    },

    MuiTableCell: {
      root: {
        position: "relative",
        "&:hover": {
          border: "0",
        },
        borderBottom: "0",
      },
      body: {
        border: "0",
      },
      head: {
        border: "0",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#808080",
        fontSize: "14px",
        letterSpacing: "0",
        lineHeight: "16px",
      },
    },

    MuiTablePagination: {
      root: {
        "& last-child": {
          border: "0",
        },
      },
    },

    MuiButtonBase: {
      root: {
        color: "#808080",
        fontSize: "14px",
        letterSpacing: "0",
        lineHeight: "16px",
      },
    },

    MuiTableHead: {
      root: {
        borderBottom: "1px solid #979797",
        borderTop: "1px solid #979797",
      },
    },

    MuiFab: {
      primary: {
        backgroundColor: "#F54022",
      },
      secondary: {
        backgroundColor: "#84D3E6",
      },
    },
  },
  palette: {
    primary: {
      main: "#D52A1E",
      dark: "#ba1206",
      900: "#ba1206",
      800: "#c82216",
      700: "#d52a1e",
      600: "#e73523",
      500: "#f54022",
      400: "#f15042",
      300: "#e97069",
      200: "#f29893",
      100: "#ffccce",
      50: "#ffebed",
    },
    secondary: {
      main: "#119BC8",
      dark: "#ba1206",
      900: "#005882",
      800: "#0078A3",
      700: "#0089B6",
      600: "#119BC8",
      500: "#1CA9D6",
      400: "#3AB5D9",
      300: "#59C2DD",
      200: "#84D3E6",
      100: "#B4E5EF",
      50: "#E1F5F9",
    },
    error: { main: "#B00020" },
    background: { default: "#FFFFFF" },
    text: {},
    common: {},
  },
  typography: {
    fontFamily: [
      "Roboto",
      "URW-DIN",
      "Merriweather",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    h1: {
      fontSize: "96px",
      fontWeight: 300,
      letterSpacing: "-1.5px",
      lineHeight: "112px",
      color: "rgba(0,0,0,0.87)",
    },
    h2: {
      fontSize: "60px",
      fontWeight: 300,
      letterSpacing: "-.5px",
      lineHeight: "72px",
      color: "rgba(0,0,0,0.87)",
    },
    h3: {
      fontSize: "96px",
      lineHeight: "56px",
      color: "rgba(0,0,0,0.87)",
      padding: "0",
    },
    h4: {
      fontSize: "34px",
      lineHeight: "36px",
      color: "rgba(0,0,0,0.87)",
    },
    h5: {
      fontSize: "24px",
      letterSpacing: "0.18px",
      lineHeight: "24px",
      color: "rgba(0,0,0,0.87)",
    },
    h6: {
      fontSize: "20px",
      fontWeight: 500,
      letterSpacing: "0.15px",
      lineHeight: "24px",
      color: "rgba(0,0,0,0.87)",
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: 500,
      letterSpacing: "0.15px",
      lineHeight: "24px",
      color: "rgba(0,0,0,0.87)",
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: 500,
      letterSpacing: "0.1px",
      lineHeight: "24px",
      color: "rgba(0,0,0,0.87)",
    },
    body1: {
      fontSize: "16px",
      letterSpacing: ".5px",
      lineHeight: "24px",
      color: "rgba(0,0,0,0.87)",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 300,
      letterSpacing: "0.25px",
      lineHeight: "20px",
      color: "rgba(0,0,0,0.87)",
    },
    button: {
      fontSize: "14px",
      fontWeight: 500,
      letterSpacing: "1.25px",
      lineHeight: "16px",
      color: "rgba(0,0,0,0.87)",
    },
    caption: {
      fontSize: "12px",
      letterSpacing: "0.4px",
      lineHeight: "16px",
      color: "rgba(0,0,0,0.87)",
    },
    overline: {
      fontSize: "10px",
      fontWeight: 500,
      letterSpacing: "1.5px",
      lineHeight: "16px",
      color: "rgba(0,0,0,0.87)",
    },
  },
});

export default theme;
