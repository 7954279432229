import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export const useJwtInfo = () => {
  const [jwtInfo, setJwtInfo] = useState<AzureDetails | null>(null);
  const [username, setUsername] = useState<string>("");
  const [initials, setInitials] = useState<string>("");
  const [lillyUserId, setLillyUserId] = useState<string>("");
  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    if (accessToken) {
      try {
        const info = jwt_decode<AzureDetails>(accessToken);
        const name = info?.name.replace(/ - Network/g, "");
        const uid = info.uid;

        setUsername(name);
        setInitials(
          name
            .split(" ")
            .map((name) => name[0])
            .join("")
            .toUpperCase(),
        );
        setLillyUserId(uid);
        setJwtInfo(info);
      } catch (error) {
        console.warn(error);
      }
    } else {
      console.warn("JWT is undefined");
    }
  }, [accessToken]);

  return { jwtInfo, username, initials, lillyUserId };
};
