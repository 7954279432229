import React from "react";
import HomePage from "../../pages/HomePage";
import { StudyInformationPage } from "../../pages/StudySetupPage/StudyInformationPage/StudyInformationPage";
import { StudyPhasePage } from "../../pages/StudySetupPage/StudyPhasePage/StudyPhasePage";
import { StudyStatePage } from "../../pages/StudySetupPage/StudyStatePage/StudyStatePage";
import { PhaseStateInformationPage } from "../../pages/StudySetupPage/PhaseStateInformationPage/PhaseStateInformationPage";
import { TreatmentGroupPage } from "../../pages/StudySetupPage/TreatmentGroupPage/TreatmentGroupPage";
import { PackageTypesPage } from "../../pages/StudySetupPage/PackageTypesPage/PackageTypesPage";
import { QSetupPage } from "../../pages/StudySetupPage/QuestionnaireSetupPage/QuestionnaireSetupPage";
import { VisitsPage } from "../../pages/StudySetupPage/VisitsPage/VisitsPage";
import { VisitSequencingPage } from "../../pages/StudySetupPage/VisitsPage/VisitSequencingPage";
import { DoseLevelPages } from "../../pages/StudySetupPage/DoseLevelPage/DoseLevelPage";
import { TitrationSetupPages } from "../../pages/StudySetupPage/TitrationSetupPage/TitrationSetupPage";
import { ScenarioActionPage } from "../../pages/StudySetupPage/ScenarioActionPage/ScenarioActionPage";
import { DispensingPlanPage } from "../../pages/StudySetupPage/DispensingPlanPage/DispensingPlanPage";
import { RandomizationFactorPage } from "../../pages/StudySetupPage/RandomizationFactorPage/RandomizationFactorPage";
import { StratumMappingPage } from "../../pages/StudySetupPage/StratumMappingPage/StratumMappingPage";
import { TreatmentMappingPage } from "../../pages/StudySetupPage/TreatmentMappingPage/TreatmentMappingPage";
import { SetParameterPage } from "../../pages/StudySetupPage/SetParameterPage/SetParameterPage";
import { OrgsUsersPage } from "../../pages/StudySetupPage/OrgsUsersPage/OrgsUsersPage";
import { DataCorrectioControlPage } from "../../pages/StudySetupPage/DataCorrectioControlPage/DataCorrectioControlPage";
import { DataCorrectioControlSitePage } from "../../pages/StudySetupPage/DataCorrectioControlSitePage/DataCorrectioControlSitePage";
import { SetupIWRSStudyPage } from "../../pages/StudySetupPage/SetupIWRSStudyPage/SetupIWRSStudyPage";
import { AuthUserMachinePage } from "../../pages/StudySetupPage/AuthUserMachinePage/AuthUserMachinePage";
import { MyProfilePage } from "../../pages/MyProfilePage/MyProfilePage";
import { StudySearchPage } from "../../pages/StudySearchPage/StudySearchPage";

import { ExcursionPage } from "../../pages/ExcusrionsPage/ExcusrionsPage";
import { MaintainStudyPage } from "../../pages/MaintailStudyPage/MaintainStudyPage";
import { StudyUtilitiesPage } from "../../pages/StudyUtilitiesPage/StudyUtilitiesPage";
import DeleteStudyContainer from "../../pages/StudySearchPage/DeleteStudy/container/DeleteStudyContainer";
import AddStudyPages from "../../pages/AddStudyPages/AddStudyPages";

const StudySetup: React.FC = () => {
  return <h1 style={{ height: "200px", background: "blue" }}>Teams</h1>;
};

export interface IRoute {
  path: string;
  sidebarName?: string;
  component: React.FC;
  nested?: any;
}

export const Routes: Array<IRoute> = [
  {
    path: "/",
    sidebarName: "Home",
    component: HomePage,
  },
  {
    path: "/my-profile",
    sidebarName: "My Profile",
    component: MyProfilePage,
  },
  {
    path: "/study-search",
    sidebarName: "Study Search",
    component: StudySearchPage,
  },
  {
    path: "/study-search/:id",
    component: DeleteStudyContainer,
  },
  {
    path: "/about-us",
    sidebarName: "Add a Study",
    component: AddStudyPages,
  },
  {
    path: "/excursions",
    sidebarName: "Excursions",
    component: ExcursionPage,
  },
  {
    path: "/study-setup",
    sidebarName: "Study Setup",
    component: StudySetup,
    nested: [
      {
        path: "/study-setup/study-information",
        sidebarName: "Study Information",
        component: StudyInformationPage,
      },
      {
        path: "/study-setup/study-phase",
        sidebarName: "Study Phase",
        component: StudyPhasePage,
      },
      {
        path: "/study-setup/study-state",
        sidebarName: "Study State",
        component: StudyStatePage,
      },
      {
        path: "/study-setup/phase-state-information",
        sidebarName: "Phase State Information",
        component: PhaseStateInformationPage,
      },
      {
        path: "/study-setup/treatment-groups",
        sidebarName: "Treatment Groups",
        component: TreatmentGroupPage,
      },
      {
        path: "/study-setup/package-types",
        sidebarName: "Package Types",
        component: PackageTypesPage,
      },
      {
        path: "/study-setup/questionnaire-setup",
        sidebarName: "questionnaire Setup",
        component: QSetupPage,
      },
      {
        path: "/study-setup/visits",
        sidebarName: "Visits",
        component: VisitsPage,
      },
      {
        path: "/study-setup/visit-sequencing",
        sidebarName: "Visit Sequencing",
        component: VisitSequencingPage,
      },
      {
        path: "/study-setup/dose-level",
        sidebarName: "Dose Level",
        component: DoseLevelPages,
      },
      {
        path: "/study-setup/titration-setup",
        sidebarName: "Titration Setup",
        component: TitrationSetupPages,
      },
      {
        path: "/study-setup/scenario-action",
        sidebarName: "Scenario Action",
        component: ScenarioActionPage,
      },
      {
        path: "/study-setup/dispensing-plan",
        sidebarName: "Dispensing Plan",
        component: DispensingPlanPage,
      },
      {
        path: "/study-setup/randomization-factor",
        sidebarName: "Randomization Factor",
        component: RandomizationFactorPage,
      },
      {
        path: "/study-setup/stratum-mapping",
        sidebarName: "Stratum Mapping",
        component: StratumMappingPage,
      },
      {
        path: "/study-setup/treatment-mapping",
        sidebarName: "Treatment Mapping",
        component: TreatmentMappingPage,
      },
      {
        path: "/study-setup/set-parameter",
        sidebarName: "Set Parameter",
        component: SetParameterPage,
      },
      {
        path: "/study-setup/org-users",
        sidebarName: "Org & Users",
        component: OrgsUsersPage,
      },
      {
        path: "/study-setup/data-correction-control",
        sidebarName: "Data Correction Control",
        component: DataCorrectioControlPage,
      },
      {
        path: "/study-setup/data-correction-control-for-site",
        sidebarName: "Data Correction Control for Site",
        component: DataCorrectioControlSitePage,
      },
      {
        path: "/study-setup/setup-iwrs-study-profiles",
        sidebarName: "Setup IWRS Study Profile",
        component: SetupIWRSStudyPage,
      },
      {
        path: "/study-setup/auth-user-machine",
        sidebarName: "Auth User Machine",
        component: AuthUserMachinePage,
      },
    ],
  },
  {
    path: "/maintain-study",
    sidebarName: "Maintain Study",
    component: MaintainStudyPage,
  },
  {
    path: "/reference-data",
    sidebarName: "Reference Data",
    component: MaintainStudyPage,
  },
  {
    path: "/study-utilities",
    sidebarName: "Study Utilities",
    component: StudyUtilitiesPage,
  },
];

export default Routes;
