import React from "react";
// import styled from 'styled-components';
// import { grayLight } from 'styleguide/colors';
import { DesignCredits } from "./design-credits";
import { MadeWith } from "./made-with";

// const StyledFooter = styled.footer`
//   font-size: 12px;
//   margin-top: auto;
//   padding: .5rem 0.5rem;
//   display: flex;
//   color: ${grayLight};
//   justify-content: space-between;
// `
import { styled } from "@material-ui/core";

export const StyledFooter = styled("div")({
  fontSize: "12px",
  marginTop: "auto",
  padding: ".5rem 0.5rem",
  display: "flex",
  color: "grey",
  justifyContent: "space-between",
});

export const Footer = () => (
  <StyledFooter>
    <DesignCredits
      content="Misha"
      arialLabel="Know more about Misha and her work"
      link="https://www.linkedin.com/in/misha-mahto-69b63242/"
    ></DesignCredits>
    {/* <MadeWith description="Made with heart and" /> */}
  </StyledFooter>
);
