import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  fetchEnvironmentError,
  fetchEnvironmentSuccess,
} from "./EnvironmentActions";
import { IEnvironmentRaw, EnvironmentActionTypes } from "./EnvironementTypes";
import { IMetaAction } from "../index";
import HttpService from "../../ApiService/HttpService";

/**
 * @desc Business logic of effect.
 */
function* handleFetch(action: IMetaAction): Generator {
  try {
    const res: IEnvironmentRaw | any = yield call(
      HttpService,
      action.meta.method,
      action.meta.route,
      action.payload,
    );
    yield put(fetchEnvironmentSuccess(res));
  } catch (err) {
    if (err instanceof Error) {
      yield put(fetchEnvironmentError(err.stack!));
    } else {
      yield put(fetchEnvironmentError("An unknown error occured."));
    }
  }
}

/**
 * @desc Watches every specified action and runs effect method and passes action args to it
 */
function* watchFetchRequest(): Generator {
  yield takeLatest(EnvironmentActionTypes.FETCH_ENVIRONMENT, handleFetch);
}

/**
 * @desc saga init, forks in effects, other sagas
 */
export default function* environmentSaga() {
  yield all([fork(watchFetchRequest)]);
}
