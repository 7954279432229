import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorCallBack from "../../pages/Error/ErrorCallback";

export type ErrorInfoType = {
  errorInfo?: string;
};

export const WithErrorBoundary = <P extends JSX.Element>(
  WrappedComponent: React.ComponentType<P & ErrorInfoType>,
) => {
  const [extraInfo, setExtraInfo] = useState("");
  useEffect(() => {
    setExtraInfo("Error Info.");
  }, [extraInfo]);

  return (props: P) => {
    return (
      <ErrorBoundary
        FallbackComponent={ErrorCallBack}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
          window.location.reload();
        }}
      >
        <WrappedComponent {...props} errorInfo={extraInfo} />
      </ErrorBoundary>
    );
  };
};
