import React, { useState, useEffect, FC } from "react";
import { TextField } from "@material-ui/core";
import {
  Column,
  FirstColumn,
  ResetButton,
  Row,
  RowNext,
  SearchButton,
} from "./styles";
import { AutocompleteInput } from "../../../components/AutoComplete/Autocomplete";
import SearchResult from "./SearchResult";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../State";
import { ISearchStudyState } from "../../../State/SearchStudy/SearchStudyTypes";
import { fetchStudy } from "../../../State/SearchStudy/SearchStudyActions";
import Environment from "../../../config/environment";
import Cookies from "js-cookie";
import { useJwtInfo } from "../../../hooks/JwtInfo";

const STUDY_STATUS = [
  { title: "Open", id: "Open" },
  { title: "Closed", id: "Closed" },
];

type DropDownValue = {
  title: string | null;
  id: number | string | null;
};

type Facility = { recid: number; facilityCode: string; facility: string };

export const SearchBox: FC = () => {
  const dispatch = useDispatch();
  const [studyCode, setStudyCode] = useState<string | null>("");
  const [projectId, setProjectId] = useState<string | null>("");
  const [altStudyName, setAltStudyName] = useState<string | null>("");
  const [status, setStatus] = useState<DropDownValue>({
    title: null,
    id: null,
  });
  const [facilityCode, setFacilityCode] = useState<DropDownValue>({
    title: null,
    id: null,
  });

  const [facilities, setFacilities] = useState<Facility[]>([]);

  useEffect(() => {
    const { apiUrl } = new Environment();
    const token = Cookies.get("accessToken");
    var requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(apiUrl + "/study/facility_code", requestOptions)
      .then((response) => response.text())
      .then((result) => setFacilities(JSON.parse(result)))
      .catch((error) => console.log("error", error));
  }, []);

  const { lillyUserId } = useJwtInfo();

  const handleButtonClick = () => {
    const payload = {
      STUDY_CODE: studyCode,
      RES_PROJ_ID: projectId,
      STUDY_STATUS: status !== null ? status.id : "",
      FACILITY_CODE: facilityCode !== null ? facilityCode.id : "",
      ALTERNATIVE_STUDY_NAME: altStudyName,
    };

    dispatch(fetchStudy(payload, lillyUserId));
  };

  const clearState = () => {
    setStudyCode("");
    setProjectId("");
    setAltStudyName("");
    setStatus({ title: null, id: null });
    setFacilityCode({ title: null, id: null });
  };

  const mapFacilities = (facilities?: Facility[]) =>
    facilities && !!facilities.length
      ? facilities.map(({ facilityCode, facility }) => ({
          title: `${facilityCode} - ${facility}`,
          id: facilityCode,
        }))
      : [];

  const stateToProps: ISearchStudyState = useSelector(
    ({ post, router }: IApplicationState) => {
      return {
        loading: post.loading,
        error: post.error,
        data: post.data,
        pathName: router?.location.pathname,
      };
    },
  );

  return (
    <div>
      <h3 style={{ color: "grey" }}>Search Study:</h3>
      <Row>
        <FirstColumn>
          <TextField
            id="studyCode-input"
            variant="filled"
            autoComplete="off"
            value={studyCode}
            onChange={(e) => setStudyCode(e.target.value)}
            type="text"
            label="Study Code"
            fullWidth
          />
        </FirstColumn>
        <Column>
          <TextField
            variant="filled"
            autoComplete="off"
            value={projectId}
            onChange={(e) => setProjectId(e.target.value)}
            type="text"
            label="Rearch Project Code"
            fullWidth
          />
        </Column>
        <Column>
          <AutocompleteInput
            label="Facilities"
            options={mapFacilities(facilities)}
            value={facilityCode}
            setValue={setFacilityCode}
          />
        </Column>
        <Column>
          <AutocompleteInput
            label="Status"
            options={STUDY_STATUS}
            value={status}
            setValue={setStatus}
          />
        </Column>
      </Row>
      <RowNext>
        <FirstColumn>
          <TextField
            variant="filled"
            autoComplete="off"
            value={altStudyName}
            onChange={(e) => setAltStudyName(e.target.value)}
            type="text"
            label="Alternative Study Name"
            fullWidth
          />
        </FirstColumn>
      </RowNext>
      <RowNext>
        <SearchButton
          variant="contained"
          disabled={false}
          onClick={handleButtonClick}
        >
          SEARCH
        </SearchButton>

        <ResetButton variant="contained" onClick={clearState}>
          RESET
        </ResetButton>
      </RowNext>

      <SearchResult {...stateToProps} />
    </div>
  );
};
