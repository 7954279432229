import { IMetaAction } from "..";
export interface ISearchStudyState {
  readonly data: ISearchStudyRaw;
  readonly loading?: boolean;
  readonly error: any;
  readonly pathName?: string;
  readonly dialog?: boolean;
  readonly deleted?: {};
}

export type ApiResponse = Record<string, any>;
export interface ISearchStudyRaw extends ApiResponse {
  resultsCount: number;
  allStudiesCount: number;
  results: StudyData[];
}

export type StudyData = {
  RES_PROJ_ID: string;
  STUDY_CODE: string;
  FACILITY_CODE: string;
  STUDY_STATUS: string;
  ALTERNATIVE_STUDY_NAME: string;
  ENROLLMENT_STATUS: string;
  DISPENSING_STATUS: string;
};

export type ISearchPayload = {
  STUDY_CODE: string | null;
  RES_PROJ_ID: string | null;
  STUDY_STATUS: number | string | null;
  FACILITY_CODE: number | string | null;
  ALTERNATIVE_STUDY_NAME: string | null;
};

export const SearchStudyActionTypes = {
  FETCH_SEARCH_STUDY: "@@SearchStudy/FETCH_SEARCH_STUDY",
  FETCH_SEARCH_STUDY_SUCCESS: "@@SearchStudy/FETCH_SEARCH_STUDY_SUCCESS",
  FETCH_SEARCH_STUDY_ERROR: "@@SearchStudy/FETCH_SEARCH_STUDY_ERROR",
};

export interface IDispatchToProps {
  fetchStudy: () => IMetaAction;
}
