import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { fetchStudyError, fetchStudySuccess } from "./SearchStudyActions";
import { ISearchStudyRaw, SearchStudyActionTypes } from "./SearchStudyTypes";
import { IMetaAction } from "../index";
import apiCaller from "../../ApiService/ApiCaller";
/**
 * @desc Business logic of effect.
 */
function* handleFetch(action: IMetaAction): Generator {
  try {
    const res: ISearchStudyRaw[] | any = yield call(
      apiCaller,
      action.meta.method,
      action.meta.route,
      action.payload,
    );

    yield put(fetchStudySuccess(res));
  } catch (err) {
    if (err instanceof Error) {
      yield put(fetchStudyError(err.stack!));
    } else {
      yield put(fetchStudyError("An unknown error occured."));
    }
  }
}

/**
 * @desc Watches every specified action and runs effect method and passes action args to it
 */
function* watchFetchRequest(): Generator {
  yield takeLatest(SearchStudyActionTypes.FETCH_SEARCH_STUDY, handleFetch);
}

/**
 * @desc saga init, forks in effects, other sagas
 */
export default function* searchStudySaga() {
  yield all([fork(watchFetchRequest)]);
}
