import { action } from "typesafe-actions";
import composeQueryString from "../../services/compose-query-string/compose-query-string";
import {
  ISearchPayload,
  ISearchStudyRaw,
  SearchStudyActionTypes,
} from "./SearchStudyTypes";
export const fetchStudy = (
  {
    STUDY_CODE,
    RES_PROJ_ID,
    STUDY_STATUS,
    FACILITY_CODE,
    ALTERNATIVE_STUDY_NAME,
  }: ISearchPayload,
  lillyUserId: string,
) => {
  const query = composeQueryString({
    ...(!!STUDY_CODE && { STUDY_CODE }),
    ...(!!RES_PROJ_ID && { RES_PROJ_ID }),
    ...(!!STUDY_STATUS && { STUDY_STATUS }),
    ...(!!FACILITY_CODE && { FACILITY_CODE }),
    ...(!!ALTERNATIVE_STUDY_NAME && { ALTERNATIVE_STUDY_NAME }),
  });

  return action(
    SearchStudyActionTypes.FETCH_SEARCH_STUDY,
    { lillyUserId },
    {
      method: "post",
      route: `/study/searchStudy${query}`,
    },
  );
};

export const fetchStudySuccess = (data: ISearchStudyRaw[]) =>
  action(SearchStudyActionTypes.FETCH_SEARCH_STUDY_SUCCESS, data);
export const fetchStudyError = (message: string) =>
  action(SearchStudyActionTypes.FETCH_SEARCH_STUDY_ERROR, message);
