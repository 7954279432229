import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { StyledTableCell, StyledTableContainer, useStyles } from "./Style";

export const Detail: React.FC = () => {
  const classes = useStyles();

  return (
    <StyledTableContainer>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: "15%" }}>
                Research Project Code
              </StyledTableCell>
              <StyledTableCell align="left" style={{ width: "10%" }}>
                Facility
              </StyledTableCell>
              <StyledTableCell align="left" style={{ width: "10%" }}>
                Study Code
              </StyledTableCell>
              <StyledTableCell align="left">Protocol Title</StyledTableCell>
              <StyledTableCell align="left">Description</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={"Project Code"}>
              <TableCell component="th" scope="row">
                {"B1Y"}
              </TableCell>
              <TableCell align="left">{"JE - Japan"}</TableCell>
              <TableCell align="left">{"HCLV"}</TableCell>
              <TableCell align="left">
                {
                  "A Phase 3, Randomized, Placebo-Controlled, Double-Blind, Parallel-Design Study to Evaluate the Short-Term, Fixed Dose Efficacy and Safety of LY110140 Once Daily Dosing in Japanese Patients with Major Depressive Disorder"
                }
              </TableCell>
              <TableCell align="left">
                {
                  "A Phase 3, Randomized, Placebo-Controlled, Double-Blind, Parallel-Design Study to Evaluate the Short-Term, Fixed Dose Efficacy and Safety of LY110140 Once Daily Dosing in Japanese Patients with Major Depressive Disorder"
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </StyledTableContainer>
  );
};
