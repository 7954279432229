import { applyMiddleware, compose, createStore, Store } from "redux";
import { IApplicationState, rootReducer, rootSaga } from "./index";
import { routerMiddleware } from "connected-react-router";

import { History } from "history";
import sagaMiddleware from "./Middleware/Saga";

export default function configureStore(
  history: History,
  initialState: IApplicationState,
): Store<IApplicationState> {
  const enhancers: Array<any> = [];
  const middlewares = [routerMiddleware(history), sagaMiddleware];
  if (process.env.NODE_ENV === "development") {
    const devToolsExtension =
      ((window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__()) ||
      compose;
    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension);
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middlewares),
    ...enhancers,
  );
  const store = createStore(
    rootReducer(history),
    initialState,
    composedEnhancers,
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
