import {
  IEnvironmentRaw,
  IEnvironmentState,
  EnvironmentActionTypes,
} from "./EnvironementTypes";
export const initialState: IEnvironmentState = {
  data: {},
  errors: [],
  loading: false,
  pathName: "",
};
export const environmentReducer = (
  state: IEnvironmentState = initialState,
  action: { type: string; payload: IEnvironmentRaw | never[] | {} },
): IEnvironmentState => {
  switch (action.type) {
    case EnvironmentActionTypes.FETCH_ENVIRONMENT: {
      return { ...state, loading: true };
    }
    case EnvironmentActionTypes.FETCH_ENVIRONMENT_SUCCESS: {
      return { ...initialState, data: action.payload };
    }
    case EnvironmentActionTypes.FETCH_ENVIRONMENT_ERROR: {
      return {
        ...state,
        data: action.payload,
      };
    }
    default:
      return state;
  }
};
