import React from "react";
import imageUrl from "../../assets/profile.jpg";

var styles = {
  image: {
    height: "270px",
    width: "270px",
    boxShadow: "0 1px 3px rgba(246,38,88,0.12), 0 1px 2px rgba(246,38,88,0.24)",
    borderRadius: "50%",
    position: "absolute",
    margin: "0 auto",
    left: "0",
    right: "0",
    padding: "5px",
    border: "solid 7px pink",
    top: "30%",
  },
};

export const ProfileImage = (props) => (
  <img src={imageUrl} alt="" style={styles.image} />
);
