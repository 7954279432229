import React from "react";
import TableComponent from "../../../components/Table";
import { ISearchStudyState } from "../../../State/SearchStudy/SearchStudyTypes";
import { ProgressButton } from "./styles";
export interface ColumnType {
  title: string;
  field: string;
}
const columns: ColumnType[] = [
  {
    title: "Research Project Code",
    field: "RES_PROJ_CODE",
  },
  {
    title: "Study Code",
    field: "STUDY_CODE",
  },
  {
    title: "Facility",
    field: "FACILITY_CODE",
  },
  {
    title: "Study Status",
    field: "STUDY_STATUS",
  },
  {
    title: "Alternative Study Name",
    field: "ALTERNATIVE_STUDY_NAME",
  },
  {
    title: "Enrollment Status",
    field: "ENROLLMENT_STATUS",
  },
  {
    title: "Dispensing Status",
    field: "DISPENSING_STATUS",
  },
];
type AllProps = ISearchStudyState;
const SearchResult: React.FC<AllProps> = ({
  data,
  loading,
  error,
}: AllProps) => {
  if (loading) {
    return <div>{loading && <ProgressButton size={24} />}</div>;
  }

  if (!!error) {
    return <div>There was an error: {error}</div>;
  }
  const { results, viewingAllStudies } = data;
  if (results && results.length > 0) {
    return (
      <div>
        <TableComponent
          sortDefault={"STUDY_CODE"}
          data={results}
          columns={columns}
        ></TableComponent>
      </div>
    );
  } else if (viewingAllStudies && results.length === 0) {
    return (
      <div style={{ paddingTop: "30px" }}> Couldn't find any studies. </div>
    );
  }
  return <div></div>;
};

export default SearchResult;
