import { IMetaAction } from "..";
export interface IModalState {
  readonly isModalOpen: boolean;
  readonly loading?: boolean;
  readonly errors?: [];
  readonly pathName?: string;
}

export interface IModalRaw {
  open: boolean;
  message: string;
}
export const ModalActionTypes = {
  OPEN_MODAL: "@@MODAL/OPEN_MODAL",
  CLOSE_MODAL: "@@MODAL/CLOSE_MODAL",
  MODAL_OK_CLICK: "@@MODAL/OK_CLICK",
  MODAL_CANCEL_CLICK: "@@MODAL/MODAL_CANCEL_CLICK",
};

export interface IDispatchToProps {
  showConfirmation: () => IMetaAction;
}
