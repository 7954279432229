import React from "react";
import { TableBody as MUITableBudy } from "@material-ui/core";
import Row from "./Row";
import { ColumnType } from "../../pages/StudySearchPage/Container/SearchResult";

export interface TableBodyProps {
  data: any[];
  columns: ColumnType[];
}

export const TableBody: React.FC<TableBodyProps> = ({ data, columns }) => {
  return (
    <MUITableBudy>
      {data.map((dataItem: any, index: number) => (
        <Row key={index} rowValue={dataItem} columns={columns} />
      ))}
    </MUITableBudy>
  );
};
