import { Button, styled, Typography } from "@material-ui/core";

export const YesButton = styled(Button)({
  backgroundColor: "#000000",
  width: 70,
  height: 30,
  color: "white",
  marginTop: 10,
  marginLeft: 38,
  "&:hover": {
    backgroundColor: "#000000",
  },
});

export const NoButton = styled(Button)({
  backgroundColor: "#b6b6b6",
  width: 70,
  height: 30,
  marginTop: 10,
  "&:hover": {
    backgroundColor: "#bdbdbd",
  },
});

export const StyledTypography = styled(Typography)({
  width: "60%",
  padding: 10,
});
