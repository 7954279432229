import React from "react";
import "./styles.css";
// import styled from "styled-components";
// import { themeColor, grayLight } from "styleguide/colors";

// const StyledSocialArea = styled.div`
//   padding: 2rem;
//   font-size: 35px;
//   margin: 0 auto;
// `;

// const StyledMedia = styled.a`
//   margin: 0 .5rem;
//   &:active,
//   &:link,
//   &:visited {
//     color: ${grayLight};
//     text-decoration: none;
//     outline: none;
//   }
//   &:hover {
//     color: #e60052;
//     cursor: pointer;
//   }
// `;
import { styled } from "@material-ui/core";

export const StyledSocialArea = styled("div")({
  padding: "2rem",
  fontSize: "35px",
  margin: " 0 auto",
});

export const StyledMedia = styled("a")({
  margin: "0 .5rem",
  color: "grey",
  // color: "#e60052",
  // &:active,
  // &:link,
  // &:visited {
  //   color: ${grayLight};
  //   text-decoration: none;
  //   outline: none;
  // }
  // &:hover {
  //   color: #e60052;
  //   cursor: pointer;
  // }
});

export const SocialArea = ({ socialLinks }) => (
  <StyledSocialArea>
    {socialLinks.map(({ icon, link }, index) => (
      <StyledMedia
        rel="noreferrer"
        role="link"
        target="_blank"
        key={index}
        className={`fa ${icon}`}
        href={link}
      />
    ))}
  </StyledSocialArea>
);
