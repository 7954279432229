import React from "react";
// import styled from "styled-components";
// import { black } from 'styleguide/colors';

// const StyledName = styled.h2`
//   color: #262626;
//   font-weight:normal;
//   text-transform:uppercase;`;

import { styled } from "@material-ui/core";

export const StyledName = styled("h2")({
  color: "#262626",
  fontWeight: "normal",
  textTransform: "uppercase",
});

export const Name = ({ name }) => <StyledName>{name}</StyledName>;
