import {
  AppBar as MUIAppBar,
  Toolbar,
  Typography,
  Avatar as MUIAvatar,
  styled,
  Badge,
  Button,
  Grid,
} from "@material-ui/core";

export const AppBar = styled(MUIAppBar)({
  backgroundColor: "transparent",
  flexDirection: "row",
});

export const ECTSImage = styled("img")(({ theme }) => ({
  height: theme.spacing(10),
}));

export const Content = styled("div")({ width: "100%" });

export const TopToolbar = styled(Toolbar)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#000000",
  height: theme.spacing(5),
  minHeight: "unset",
}));

export const BottomToolbar = styled(Toolbar)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#6F6F6F",
  height: theme.spacing(5),
  minHeight: "unset",
}));

export const Title = styled(Typography)({
  flex: 1,
  color: "#FFFFFF",
  fontFamily: "URW-DIN",
});

export const Avatar = styled(MUIAvatar)(({ theme }) => ({
  backgroundColor: "#D8D8D8",
  width: theme.spacing(3),
  height: theme.spacing(3),
  color: "#575757",
  fontSize: "12px",
  letterSpacing: 0,
  lineHeight: "14px",
}));

export const User = styled(Grid)(({ theme }) => ({
  "& > *": {
    marginRight: theme.spacing(2),
  },
}));

export const StudyName = styled(Typography)({
  color: "#FFFFFF",
  flex: 1,
  fontSize: "15px",
  letterSpacing: 0,
  lineHeight: "18px",
  fontWeight: 500,
});

export const StyleBadge = styled(Badge)({
  "&& .MuiBadge-badge": {
    backgroundColor: "#008C09",
    color: "#44b700",
  },
});

export const Logout = styled(Button)({
  "&& .MuiButton-label": {
    textTransform: "capitalize",
    color: "#FFFFFF",
  },
});
